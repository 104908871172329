import styled from "styled-components"

export const StyledSingleProjectSection = styled.section`
  position: relative;

  .gatsby-image-wrapper-constrained {
    display: block;
    margin-top: -1px;
  }
`

export const StyledProjectInfo = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  font-family: ${props => props.theme.fonts.primary};

  @media ${props => props.theme.sizes.laptop} {
    position: absolute;
    height: 60vh;
  }

  .infoBox {
    &__wrapper {
      margin: 0 auto;
      background: rgba(255, 255, 255, 0.87);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5%;

      @media ${props => props.theme.sizes.laptop} {
        width: 70%;
        padding: 0 10% 5%;
      }
    }

    &__text {
      text-align: center;
      font-size: 1rem;

      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.4rem;
      }
    }
    &__scope {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;
      margin: 0;

      @media ${props => props.theme.sizes.laptop} {
        font-size: 1.7rem;
        margin: 1rem 0;
      }
    }
  }
`
