import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NextItem from "../../components/molecules/NextItem"
import PreviousItem from "../../components/molecules/PreviousItem"
import NextPreview from "../../components/atoms/NextPreview"

import {
  StyledSingleProjectSection,
  StyledProjectInfo,
} from "./singleProject.style.js"

const CaseStudy = props => {
  const { title } = props.pageContext
  const { content } = props.pageContext
  const ACF = props.pageContext.ACF_realizacje

  const SEO = props.pageContext.seo

  const GalleryItem = ACF.gallerywp.map((item, index) => {
    return (
      <GatsbyImage
        key={index}
        image={getImage(item.localFile)}
        alt={item.title}
        title={item.title}
      />
    )
  })

  return (
    <div>
      <Layout seoData={SEO} seoUrl={props.pageContext.uri} overflow='hidden'>
        <StyledSingleProjectSection>
          <StyledProjectInfo>
            <div className="infoBox__wrapper">
              <div className="infoBox__logo">
                <GatsbyImage
                  image={getImage(ACF.logo.localFile)}
                  alt={ACF.logo.altText || "projectLogo"}
                  title={ACF.logo.title || "projectLogo"}
                />
              </div>
              <div
                className="infoBox__text"
                dangerouslySetInnerHTML={{ __html: ACF.description }}
              />
              <h1
                className="infoBox__scope"
                dangerouslySetInnerHTML={{ __html: ACF.scope }}
              />
            </div>
          </StyledProjectInfo>
          {GalleryItem}
        </StyledSingleProjectSection>
        <NextPreview>
          {props.pageContext.previous && (
            <PreviousItem
              prevLink={props.pageContext.previous.uri}
              prevName="Poprzednia realizacja"
            />
          )}
          {props.pageContext.next && (
            <NextItem
              nextLink={props.pageContext.next.uri}
              nextName="Następna realizacja"
            />
          )}
        </NextPreview>
      </Layout>
    </div>
  )
}
export default CaseStudy

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
